import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-order-action',
	template: `
		@if (label(); as label) {
			<div class="mb-1">
				{{ label | translate }}
			</div>
		}
		<button
			class="font-medium text-bl-1-700 hover:underline hover:text-bl-1-400 disabled:pointer-events-none disabled:text-bl-1-200 disabled:cursor-not-allowed"
			(click)="clickEvent.emit($event)"
			[disabled]="disabled()"
		>
			{{ text() | translate }}
		</button>
	`,
	standalone: true,
	imports: [TranslateModule],
})
export class OrderActionComponent {
	label = input<string>();
	text = input<string>();
	disabled = input<boolean>();
	clickEvent = output<any>();
}
