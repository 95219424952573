export type CANCELLATION_REASON =
	| 'DELIVERY_ISSUE'
	| 'CUSTOMER_WISH'
	| 'CUSTOMER_NOT_RESPONDING'
	| 'STORAGE_ISSUE'
	| 'OTHER';

export const CANCELLATION_REASONS = {
	DELIVERY_ISSUE: 'DELIVERY_ISSUE' as CANCELLATION_REASON,
	CUSTOMER_WISH: 'CUSTOMER_WISH' as CANCELLATION_REASON,
	CUSTOMER_NOT_RESPONDING: 'CUSTOMER_NOT_RESPONDING' as CANCELLATION_REASON,
	STORAGE_ISSUE: 'STORAGE_ISSUE' as CANCELLATION_REASON,
	OTHER: 'OTHER' as CANCELLATION_REASON,
};

export function getCancellationReasons() {
	return Object.keys(CANCELLATION_REASONS);
}
