@if (
	(order.orderNumber == null || order.orderNumber.length === 0) &&
	order.customerWasDeleted
) {
	<div>
		{{ 'ORDERS.ORDEROVERVIEW.USER_DELETED' | translate }}
	</div>
}
@if (
	(order.orderNumber != null && order.orderNumber.length > 0) ||
	order.customerDetails != null
) {
	<span>
		@if (order.customerDetails?.fullName) {
			<div id="fullName">
				{{
					order.customerDetails?.fullName
						| maxLengthEllipsis: 25
						| censorName: isSuperAdmin
						| displayHyphenIfNull
				}}
			</div>
		}
		@if (!order.orderNumber) {
			<div id="orderNumber">
				{{
					order.orderNumber
						| maxLengthEllipsis: 25
						| displayHyphenIfNull
				}}
			</div>
		} @else {
			<div id="orderNumber">
				<button
					class="hover:font-medium group hover:underline hover:text-bl-1-400"
					[copy-clipboard]="order.orderNumber"
					(copied)="copy()"
					(click)="$event.stopPropagation()"
				>
					{{
						order.orderNumber
							| maxLengthEllipsis: 25
							| displayHyphenIfNull
					}}
					@if (!copied) {
						<app-icons
							class="hidden group-hover:!inline"
							[iconName]="'faCopy'"
						/>
					}
					@if (copied) {
						<app-icons
							class="!text-s-1-500 hidden group-hover:!inline"
							[iconName]="'faCheck'"
						/>
					}
				</button>
			</div>
		}
	</span>
}
