@if (order.customerDetails?.address) {
	<span id="address">
		{{
			order.customerDetails?.address
				| maxLengthEllipsis: 25
				| censorStreet: isSuperAdmin
				| displayHyphenIfNull
		}}
	</span>
}
@if (order.customerDetails?.address) {
	<br />
}
@if (order.customerDetails?.postalCode || order.customerDetails?.city) {
	<span id="zipAndCity">
		{{
			(order.customerDetails?.postalCode
				? (order.customerDetails?.postalCode
						| censorPostalCode: isSuperAdmin) + ' '
				: '') +
				(order.customerDetails?.city
					? (order.customerDetails?.city | censorCity: isSuperAdmin)
					: '')
				| maxLengthEllipsis: 25
				| displayHyphenIfNull
		}}
	</span>
}
@if (order.customerDetails?.postalCode || order.customerDetails?.city) {
	<br />
}
<span id="email">
	{{
		order.customerDetails?.email
			| maxLengthEllipsis: 25
			| censorEmail: isSuperAdmin
			| displayHyphenIfNull
	}}
</span>
