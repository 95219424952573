@if (order(); as order) {
	<div class="grid grid-cols-10 gap-1 mb-2 text-xs text-g-3">
		<div class="col-span-2">
			{{ 'ORDERS.ORDEROVERVIEW.ORDER' | translate }}
		</div>
		<div class="col-span-2">
			{{ 'ORDERS.ORDEROVERVIEW.CONTACT' | translate }}
		</div>
		<div>
			{{ order | overviewDateLabel }}
		</div>
		<div>
			{{ 'ORDERS.ORDEROVERVIEW.PACKAGES.LABEL' | translate }}
		</div>
		<div class="col-span-2">
			{{ 'ORDERS.ORDEROVERVIEW.STATUS.LABEL' | translate }}
		</div>
		<div class="col-span-2">
			{{ 'ORDERS.ORDEROVERVIEW.ACTION' | translate }}
			@if (!isOrderDetails) {
				<app-icons
					[iconName]="'faChevronRight'"
					class="float-right font-black"
					name="ordereditOpener"
				/>
			}
		</div>
	</div>
	<div class="grid grid-cols-10 gap-1 text-[14px] relative h-3/4">
		<div class="col-span-2">
			<app-order-details
				[order]="order"
				[isSuperAdmin]="isSuperAdmin$ | async"
			/>
		</div>
		<div class="col-span-2">
			<app-contact-details
				[order]="order"
				[isSuperAdmin]="isSuperAdmin$ | async"
			/>
		</div>
		<div>
			<span
				id="creationDate"
				[appTooltip]="
					order
						| overviewDateValue
						| convertDateToLocal
						| date: 'short' : '' : locale
				"
			>
				{{
					order
						| overviewDateValue
						| convertDateToLocal
						| dateRelativeDisplay: locale
				}}
			</span>
		</div>
		<div>
			<span id="orderPackages">
				@if (order?.storedQuantity > 0) {
					{{
						order.storedQuantity +
							' ' +
							('ORDERS.ORDEROVERVIEW.PACKAGES.STORED' | translate)
					}}
					<br />
				}

				@if (order?.dispensedQuantity > 0) {
					{{
						order.dispensedQuantity +
							' ' +
							('ORDERS.ORDEROVERVIEW.PACKAGES.DISPENSED'
								| translate)
					}}
				}

				@if (
					order?.storedQuantity <= 0 && order?.dispensedQuantity <= 0
				) {
					-
				}
			</span>
		</div>
		<div class="col-span-2">
			<span
				class="px-3 py-1 rounded-2xl"
				[orderStateColor]="order.isOverdue ? 'OVERDUE' : order.state"
				name="orderState"
				id="orderState"
			>
				{{
					(order.isOverdue ? 'OVERDUE' : order.state)
						| orderStatusTranslateLabel
						| translate
				}}
			</span>
		</div>
		<div class="col-span-2">
			<span class="action-wrap">
				<span *normalDisplay>
					@if (
						order.state === ORDERSTATE.Readyforinput &&
						!order.isOverdue &&
						!order.isRecurrentOrder
					) {
						<app-order-action
							id="print_order_btn"
							[text]="
								'ORDERS.ORDEROVERVIEW.MANAGE_ORDER.STORAGE_CODE.ACTION_TEXT'
							"
							(clickEvent)="
								printOrder.emit({
									order: order,
									event: $event,
								})
							"
						/>
					}

					@if (
						order.state === ORDERSTATE.Readyforinput &&
						!order.isOverdue &&
						order.isRecurrentOrder
					) {
						<app-order-action
							id="print_order_pickup_btn"
							[text]="
								'ORDERS.ORDEROVERVIEW.MANAGE_ORDER.PICKUP_CODE.ACTION_TEXT'
							"
							(clickEvent)="printPickup(order, $event)"
						/>
					}
				</span>

				<app-order-action
					*demoDisplay="
						order.state === ORDERSTATE.Readyforinput &&
						!order.isOverdue
					"
					id="input_article_btn"
					[text]="
						'ORDERS.ORDEROVERVIEW.MANAGE_ORDER.INPUT_ARTICLE.ACTION_TEXT'
					"
					(clickEvent)="inputArticle(order, $event)"
					/>
					<!-- [disabled]="order.outputstate === OUTPUTSTATE.Pending" -->

				@if (
					order.state === ORDERSTATE.Readyforpickup &&
					!order.isOverdue
				) {
					<app-order-action
						id="dispense_articles_btn"
						[text]="
							'ORDERS.ORDEROVERVIEW.MANAGE_ORDER.DISPENSE_ORDER.ACTION_TEXT'
						"
						(clickEvent)="dispenseOrder.emit($event)"
						/>
						<!-- [disabled]="order.outputstate === OUTPUTSTATE.Pending" -->
				}

				@if (order.isOverdue || order.state === ORDERSTATE.Overdue) {
					<app-order-action
						id="cancel_order_btn"
						[text]="
							'ORDERS.ORDEROVERVIEW.MANAGE_ORDER.CANCEL.ACTION_TEXT'
						"
						(clickEvent)="cancelOrder.emit($event)"
						/>
						<!-- [disabled]="order.outputstate === OUTPUTSTATE.Pending" -->
				}
			</span>

			@if (
				(order.state === ORDERSTATE.Pickedup ||
					order.state === ORDERSTATE.Canceled) &&
				!order.isOverdue
			) {
				<span> - </span>
			}

			<!-- @if (order.outputstate === OUTPUTSTATE.Pending) {
				<rowa-activity-indicator class="absolute bottom-0 right-0" />
			} -->
		</div>
	</div>
}
