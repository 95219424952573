import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { StockInputDto } from 'src/app/swagger';
import { PerfectScrollbarModule } from '../../../../../../../libs/ui/src/lib/perfect-scrollbar/perfect-scrollbar.module';

@Component({
	selector: 'app-stored-packs-short-list',
	templateUrl: './order-stored-packs-short-list.component.html',
	standalone: true,
	imports: [
		PerfectScrollbarModule,
		NgClass,
		NgTemplateOutlet,
		TranslateModule,
	],
})
export class OrderStoredPacksShortListComponent {
	@Input() shelves: StockInputDto[];
	@Input() forDialog: boolean;

	public scrollbarConfig: PerfectScrollbarConfigInterface = {
		minScrollbarLength: 10,
		wheelSpeed: 0.5,
		useBothWheelAxes: false,
		suppressScrollX: true,
	};
}
