<app-confirm-dialog (close)="close()">
	<ng-template #header>
		<span>
			{{
				'ORDERS.ORDEROVERVIEW.DISPENSE.DISPENSE_ORDER_QUESTION'
					| translate
			}}
		</span>
	</ng-template>

	<ng-template #body>
		@if (order) {
			@if (
				!(isAdapterConnected$ | async) && (mode$ | async) !== 'STORERX'
			) {
				<app-connection-notification />
				<hr class="my-4 text-g-1-400" />
			}
			@if ((mode$ | async) === 'VMAX') {
				<app-vmax-dialog
					[isAdapterConnected]="isAdapterConnected$ | async"
					[orderNumber]="order.orderNumber"
					[storedQ]="vmaxStored"
					[dispensedQ]="overviewOrder.dispensedQuantity"
					[outputLocations]="getOutputLocations$ | async"
					[(selectedOutputLocation)]="selectedOutputLocation"
					[isRecurrentOrder]="overviewOrder.isRecurrentOrder"
					(gotooutput)="gotoOutput()"
				/>
			}
			@if ((mode$ | async) === 'STORERX') {
				<app-storerx-dialog
					[isAdapterConnected]="true"
					[stockInputs]="order.stockInputs"
				/>
			}
			@if ((mode$ | async) === 'MIXED') {
				<app-vmax-dialog
					[isAdapterConnected]="isAdapterConnected$ | async"
					[orderNumber]="order.orderNumber"
					[storedQ]="vmaxStored"
					[dispensedQ]="overviewOrder.dispensedQuantity"
					[outputLocations]="getOutputLocations$ | async"
					[(selectedOutputLocation)]="selectedOutputLocation"
					[isRecurrentOrder]="overviewOrder.isRecurrentOrder"
					(gotooutput)="gotoOutput()"
				/>
				<hr class="my-4 text-g-1-400" />
				<app-storerx-dialog
					[isAdapterConnected]="true"
					[stockInputs]="order.stockInputs"
				/>
			}
		} @else {
			<div class="flex flex-row justify-center w-full">
				<rowa-activity-indicator />
			</div>
		}
	</ng-template>

	<ng-template #actions>
		<app-button
			text="ORDERS.ORDEROVERVIEW.DISPENSE.DISPENSE_ORDER"
			(click)="dispenseOrder()"
			[disabled]="
				!(isDespensable$ | async) ||
				((!(isAdapterConnected$ | async) ||
					selectedOutputLocation == null) &&
					(mode$ | async) !== 'STORERX')
			"
		/>
	</ng-template>
</app-confirm-dialog>
