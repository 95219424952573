<app-confirm-dialog (close)="close()">
	<ng-template #header>
		<span>
			{{
				'ORDERS.ORDEROVERVIEW.CANCEL.CANCEL_ORDER_QUESTION' | translate
			}}
		</span>
	</ng-template>

	<ng-template #body>
		@if (order) {
			@if (
				(order.state === 'READYFORPICKUP' ||
					order.state === 'CANCELED') &&
				!(isAdapterConnected$ | async) &&
				(mode$ | async) !== 'STORERX' &&
				(mode$ | async) !== 'NONE'
			) {
				<app-connection-notification />
				<hr class="my-4 text-g-1-400" />
			}
			@if (
				!(
					order.state === 'READYFORPICKUP' &&
					!(isAdapterConnected$ | async) &&
					(mode$ | async) !== 'STORERX' &&
					(mode$ | async) !== 'NONE'
				)
			) {
				<div class="mb-2 overflow-y-auto font-bold">
					{{
						'ORDERS.ORDEROVERVIEW.CANCEL.REASON_FOR_CANCELLATION'
							| translate
					}}
					<span class="text-s-3-500 size-18px">*</span>
				</div>
				<div class="h-8">
					<rowa-dropdown-list
						required
						class="w-100"
						[items]="cancellationReasons"
						[(ngModel)]="order.cancellationReason"
						[placeholder]="
							'ORDERS.ORDEROVERVIEW.CANCEL.CHOOSE' | translate
						"
						[options]="{ hideLabel: true }"
					>
						<ng-template #selected let-selected>
							<span>{{
								!selected
									? ''
									: (selected
										| cancellationReasonTranslateLabel
										| translate)
							}}</span>
						</ng-template>

						<ng-template #option let-option>
							<span>{{
								option
									| cancellationReasonTranslateLabel
									| translate
							}}</span>
						</ng-template>
					</rowa-dropdown-list>
				</div>
				<hr class="my-4 text-g-1-400" />
			}
			@if (
				order.state === 'READYFORPICKUP' || order.state === 'CANCELED'
			) {
				@if ((mode$ | async) === 'VMAX') {
					<app-vmax-dialog
						[isAdapterConnected]="isAdapterConnected$ | async"
						[orderNumber]="order.orderNumber"
						[storedQ]="vmaxStored"
						[dispensedQ]="overviewOrder.dispensedQuantity"
						[outputLocations]="getOutputLocations$ | async"
						[(selectedOutputLocation)]="selectedOutputLocation"
						[isRecurrentOrder]="overviewOrder.isRecurrentOrder"
						(gotooutput)="gotoOutput()"
					/>
				}
				@if ((mode$ | async) === 'STORERX') {
					<app-storerx-dialog
						[isAdapterConnected]="true"
						[stockInputs]="order.stockInputs"
					/>
				}
				@if ((mode$ | async) === 'MIXED') {
					<app-vmax-dialog
						[isAdapterConnected]="isAdapterConnected$ | async"
						[orderNumber]="order.orderNumber"
						[storedQ]="vmaxStored"
						[dispensedQ]="overviewOrder.dispensedQuantity"
						[outputLocations]="getOutputLocations$ | async"
						[(selectedOutputLocation)]="selectedOutputLocation"
						[isRecurrentOrder]="overviewOrder.isRecurrentOrder"
						(gotooutput)="gotoOutput()"
					/>
					<hr class="my-4 text-g-1-400" />
					<app-storerx-dialog
						[isAdapterConnected]="true"
						[stockInputs]="order.stockInputs"
					/>
				}
				@if ((mode$ | async) !== 'NONE') {
					<hr class="my-4 text-g-1-400" />
				}
			}
			<div>
				<div class="mb-2 font-bold">
					{{
						'ORDERS.ORDEROVERVIEW.CANCEL.NOTIFY_CUSTOMER'
							| translate
					}}
				</div>
				@if (order.customerDetails?.email) {
					<div class="flex space-x-3">
						<div class="flex">
							<rowa-checkbox
								[(ngModel)]="order.sendCancellationEmail"
							/>
						</div>
						<div class="flex-1 space-y-3">
							<div>
								{{
									('ORDERS.ORDEROVERVIEW.CANCEL.NOTIFY.SEND_EMAIL_TO'
										| translate) +
										' ' +
										(order.customerDetails?.email
											| censorEmail: isSuperAdmin)
								}}
							</div>
							@if (order.customerDetails?.phoneNumber) {
								<div>
									{{
										('ORDERS.ORDEROVERVIEW.CANCEL.NOTIFY.CALL_NR_LABEL'
											| translate) +
											' ' +
											(order.customerDetails?.phoneNumber
												| censorPhone: isSuperAdmin
												| displayHyphenIfNull)
									}}
								</div>
							}
						</div>
					</div>
				}
				@if (
					order.customerDetails?.phoneNumber &&
					!order.customerDetails?.email
				) {
					<div>
						<app-notify-toast
							[phone]="order.customerDetails?.phoneNumber"
							[LABEL_ID]="
								'ORDERS.ORDEROVERVIEW.CANCEL.NOTIFY.PHONE.LABEL'
							"
							[INFO_ID]="
								'ORDERS.ORDEROVERVIEW.CANCEL.NOTIFY.PHONE.INFO'
							"
						/>
					</div>
				}
				@if (
					!order.customerDetails?.phoneNumber &&
					!order.customerDetails?.email
				) {
					<div>
						<app-notify-toast
							[LABEL_ID]="
								'ORDERS.ORDEROVERVIEW.CANCEL.NOTIFY.NONOTIFICATION.LABEL'
							"
							[INFO_ID]="
								'ORDERS.ORDEROVERVIEW.CANCEL.NOTIFY.NONOTIFICATION.INFO'
							"
						/>
					</div>
				}
			</div>
		} @else {
			<div class="flex flex-row justify-center w-full">
				<rowa-activity-indicator />
			</div>
		}
	</ng-template>
	<ng-template #actions>
		<div class="flex justify-end text-sm gap-x-4">
			<app-button
				text="ORDERS.ORDEROVERVIEW.CANCEL.KEEP_ORDER"
				theme="secondary"
				(click)="close()"
			/>

			<app-button
				text="ORDERS.ORDEROVERVIEW.CANCEL.CANCEL_ORDER"
				(click)="cancelOrder()"
				[disabled]="
					order?.cancellationReason == null ||
					(((mode$ | async) === 'VMAX' ||
						(mode$ | async) === 'MIXED') &&
						((selectedOutputLocation == null &&
							order?.recurrentOrderDetails == null) ||
							!(isAdapterConnected$ | async)) &&
						order?.storedQuantity > 0)
				"
			/>
		</div>
	</ng-template>
</app-confirm-dialog>
