import { NgClass } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/core/components/dialog/dialog.service';
import { OrderStateBgColorDirective } from 'src/app/core/directives/order-state-bg-color.directive';
import { DynamicLocaleService } from 'src/app/core/services/dynamic-locale.service';
import { PrintService } from 'src/app/core/services/print.service';
import { SwaggerService } from 'src/app/core/services/swagger.service';
import {
	CancelOrderDto,
	GetOrderDto,
	GetOrderResponse,
	ORDERSTATE,
	RecurrentOrderArticleIteration,
	RecurrentOrderArticleStateEnum,
} from 'src/app/swagger';
import { CancelOrderDialogComponent } from 'src/app/views/orders/dialogs/cancel-order-dialog/cancel-order-dialog.component';
import { DispenseOrderDialogComponent } from 'src/app/views/orders/dialogs/dispense-order-dialog/dispense-order-dialog.component';
import { OrderOverviewService } from 'src/app/views/orders/order-overview/order-overview.service';
import { ScheduledOrderService } from 'src/app/views/scheduled-orders/scheduled-order.service';
import { environment } from 'src/environments/environment';
import { MODE } from 'src/environments/environment.helper';
import { OrderDialogService } from '../../../views/orders/dialogs/order-dialog.service';
import { OrderService } from '../../../views/orders/order.service';
import { OrderStateBorderColorDirective } from '../../directives/order-state-border-color.directive';
import { ImportedIconsComponent } from '../imported-icons/imported-icons.component';
import { NormalOrderOverviewComponent } from './normal-order-overview/normal-order-overview.component';
import { ScheduledOrderOverviewComponent } from './scheduled-order-overview/scheduled-order-overview.component';

@Component({
	selector: 'app-order-overview-header',
	templateUrl: './order-overview-header.component.html',
	standalone: true,
	imports: [
		NgClass,
		OrderStateBorderColorDirective,
		OrderStateBgColorDirective,
		ImportedIconsComponent,
		NormalOrderOverviewComponent,
		ScheduledOrderOverviewComponent,
	],
})
export class OrderOverviewHeaderComponent implements OnDestroy {
	@Input() order: GetOrderDto;
	@Input() isOrderDetails: boolean = false;
	@Output() loadData: EventEmitter<void> = new EventEmitter();
	selected: boolean = false;
	cancelDialogOpen: boolean = false;
	dispenseDialogOpen: boolean = false;
	subs: Subscription = new Subscription();
	@ViewChild('orderOverviewHeaderForm')
	public orderOverviewHeaderForm: NgForm;

	constructor(
		private swagger: SwaggerService,
		private printService: PrintService,
		private orderService: OrderService,
		private scheduledOrderService: ScheduledOrderService,
		private router: Router,
		private route: ActivatedRoute,
		private dialogService: DialogService,
		private orderDialogService: OrderDialogService,
		private orderOverviewService: OrderOverviewService,
	) {
		this.subs.add(
			this.orderService.cancelOrder.subscribe(() =>
				this.cancelOrder(undefined),
			),
		);
		this.subs.add(
			this.orderService.dispenseOrder.subscribe(() =>
				this.dispenseOrder(undefined),
			),
		);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	async checkOrderNumberUnique(orderNumberInput, order: GetOrderResponse) {
		if (
			orderNumberInput.value == null ||
			orderNumberInput.value == '' ||
			orderNumberInput.value == order.orderNumber
		) {
			return;
		}
		this.swagger.OrderServiceAPI.apiOrderIsOrderNumberAlreadyInUseGet(
			order.orderNumber,
		).subscribe((orderNumberUnique) => {
			if (orderNumberUnique) {
				orderNumberInput.model.control.setErrors({
					DUPLICATED_ORDERNUMBER: true,
				});
			}
		});
	}

	stopTriggerEvent(event) {
		if (event != null) {
			event.stopPropagation();
		}
	}

	printOrder = (data) => {
		this.stopTriggerEvent(data.event);
		this.printService.curInputOrder.next(data.order);
	};

	async triggerDetails(order: GetOrderDto) {
		if (this.isOrderDetails) {
			return;
		}

		if (order.state === ORDERSTATE.Scheduled) {
			this.scheduledOrderService.orderLoader.next(order.storageCode);
		} else {
			this.orderService.orderLoader.next(order.storageCode);
		}

		this.router.navigate(['display'], { relativeTo: this.route });
	}

	cancelOrder(event) {
		this.stopTriggerEvent(event);

		this.dialogService
			.open(CancelOrderDialogComponent, {
				overviewOrder: this.order,
			})
			.afterConfirm.subscribe((data) => {
				if (data) {
					this.onCancelDialog(data);
				}
			});
	}

	public dispenseOrder(event): void {
		this.stopTriggerEvent(event);

		this.dialogService
			.open(DispenseOrderDialogComponent, {
				overviewOrder: this.order,
			})
			.afterConfirm.subscribe(async (data) => {
				if (data) {
					await this.onDispenseDialog(data);
				}
			});
	}

	async onCancelDialog({ outputDest, order, mode }) {
		const helper = this.orderDialogService.cancelHelper.find(
			(x) => x.mode === mode,
		);

		await helper.handler({
			cancellationReason: order.cancellationReason,
			sendCancellationEmail: order.sendCancellationEmail,
			email: order.customerDetails?.email ?? null,
			outputId: outputDest?.outputId ?? null,
			storageCode: order.storageCode,
		} as CancelOrderDto);

		helper.toast(order);

		if (
			environment.MODE === MODE.DEMO ||
			environment.MODE === MODE.DEMOPROD
		) {
			this.orderOverviewService.changeFilter(ORDERSTATE.Canceled);
		}

		if (this.router.url.indexOf('orders/display') > 0) {
			this.router.navigate(['orders']);
		} else {
			this.loadData.emit();
		}
	}

	async onDispenseDialog({ outputId, order, mode }) {
		const helper = this.orderDialogService.dispenseHelper.find(
			(x) => x.mode === mode,
		);

		await helper.handler(order, outputId);
		helper.toast(order);

		if (
			environment.MODE === MODE.DEMO ||
			environment.MODE === MODE.DEMOPROD
		) {
			if (
				([] as RecurrentOrderArticleIteration[])
					.concat(
						...order.recurrentOrderArticles.map(
							(x) => x.recurrentOrderArticleIterations,
						),
					)
					.some(
						(x) =>
							x.state ===
							RecurrentOrderArticleStateEnum.Scheduled,
					)
			) {
				this.router.navigate(['scheduled-orders']);
				return;
			}
			this.orderOverviewService.changeFilter(ORDERSTATE.Pickedup);
		}

		if (this.router.url.indexOf('orders/display') > 0) {
			this.router.navigate(['orders']);
		} else {
			this.loadData.emit();
		}
	}
}
