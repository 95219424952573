import { Directive, OnChanges, Input, ElementRef } from '@angular/core';
import { ORDERSTATE } from 'src/app/swagger';

@Directive({
	selector: '[orderStateBgColor]',
	standalone: true,
})
export class OrderStateBgColorDirective implements OnChanges {
	@Input({ required: true })
	orderStateBgColor: ORDERSTATE = ORDERSTATE.Canceled;

	constructor(private element: ElementRef) {
		this.statusClasses = {};
		this.statusClasses[ORDERSTATE.Overdue] = 'bg-s-3-500';
		this.statusClasses[ORDERSTATE.Readyforinput] = 'bg-s-2-500';
		this.statusClasses[ORDERSTATE.Readyforpickup] = 'bg-bl-1-500';
		this.statusClasses[ORDERSTATE.Pickedup] = 'bg-s-1-500';
		this.statusClasses[ORDERSTATE.Canceled] = 'bg-gb-3-500';
		this.statusClasses[ORDERSTATE.Scheduled] = 'bg-[#4146e5]';
	}

	private statusClasses: { [status: number]: string };

	ngOnChanges(_) {
		for (let key in this.statusClasses) {
			this.element.nativeElement.classList.remove(
				this.statusClasses[key],
			);
		}
		this.element.nativeElement.classList.add(
			`${this.statusClasses[this.orderStateBgColor]}`,
		);
	}
}
