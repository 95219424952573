<div
	class="relative py-4 pl-6 pr-4 bg-white h-28"
	id="orderOverviewCard"
	[ngClass]="{
		'hover:cursor-pointer hover:bg-bl-1-100 hover:bg-opacity-10':
			!isOrderDetails,
		'rounded-tl-lg rounded-bl-lg': !isOrderDetails,
	}"
	[orderStateBorderColor]="order.isOverdue ? 'OVERDUE' : order.state"
	(click)="triggerDetails(order)"
>
	@if (order.state == 'SCHEDULED' || order.isRecurrentOrder) {
		<span
			[orderStateBgColor]="order.isOverdue ? 'OVERDUE' : order.state"
			class="absolute top-0 left-[-0.35rem] w-6 h-6 rounded-tl-lg rounded-br-lg"
		>
			<app-imported-icons [iconName]="'orders.overview.scheduled'" />
		</span>
	}
	@if (order.state != 'SCHEDULED') {
		<app-normal-order-overview
			[order]="order"
			[isOrderDetails]="isOrderDetails"
			(cancelOrder)="cancelOrder($event)"
			(dispenseOrder)="dispenseOrder($event)"
			(printOrder)="printOrder($event)"
		/>
	}
	@if (order.state == 'SCHEDULED') {
		<app-scheduled-order-overview
			[order]="order"
			[isOrderDetails]="isOrderDetails"
		/>
	}
</div>
