import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderDetailsDto, ORDERSTATE } from 'src/app/swagger';

@Pipe({
	name: 'overviewDateLabel',
	standalone: true,
})
export class OverviewDateLabelPipe implements PipeTransform {
	constructor(private translate: TranslateService) {}
	transform(order: OrderDetailsDto): string {
		switch (order.state) {
			case ORDERSTATE.Canceled:
				return this.translate.instant('ORDERS.ORDEROVERVIEW.CANCELED');
			case ORDERSTATE.Readyforpickup:
				return this.translate.instant(
					'ORDERS.ORDEROVERVIEW.LAST_ARTICLE_INPUT',
				);
			case ORDERSTATE.Pickedup:
				return this.translate.instant('ORDERS.ORDEROVERVIEW.PICKED_UP');
		}

		if (order.isOverdue) {
			return this.translate.instant(
				'ORDERS.ORDEROVERVIEW.READY_FOR_PICKUP',
			);
		}

		return this.translate.instant('ORDERS.ORDEROVERVIEW.CREATED');
	}
}

@Pipe({
	name: 'overviewDateValue',
	standalone: true,
})
export class OverviewDateValuePipe implements PipeTransform {
	transform(order: OrderDetailsDto): Date {
		if (order.state === ORDERSTATE.Canceled) return order.canceledDate;
		if (order.isOverdue) return order.firstInputDate;
		if (order.state === ORDERSTATE.Readyforpickup)
			return order.lastInputDate;
		if (order.state === ORDERSTATE.Pickedup) return order.pickedUpDate;
		return order.creationDate;
	}
}
