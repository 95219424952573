<perfect-scrollbar
	class="list-block"
	[config]="scrollbarConfig"
	[ngClass]="{ 'max-h-24': forDialog }"
>
	@for (shelf of shelves; track shelf.id) {
		<span class="block">
			<ng-container
				[ngTemplateOutlet]="
					forDialog ? itemTemplateDialog : itemTemplate
				"
			></ng-container>

			<ng-template #itemTemplate>
				<div
					class="overflow-hidden w-24 whitespace-nowrap text-ellipsis"
				>
					<span>
						{{ shelf.packagesAmount }}
					</span>
					x
					<span [title]="shelf.stock?.name">
						{{ shelf.stock?.name }}
					</span>
				</div>
			</ng-template>

			<ng-template #itemTemplateDialog>
				<span
					[innerHTML]="
						'AMOUNT_FROM_STOCKLOCATION'
							| translate
								: {
										amount: shelf.packagesAmount,
										stocklocation: shelf.stock?.name
								  }
					"
				>
				</span>
			</ng-template>
		</span>
	}
</perfect-scrollbar>
