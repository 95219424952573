import {
	Directive,
	OnChanges,
	Input,
	ElementRef,
	HostBinding,
} from '@angular/core';
import { ORDERSTATE } from 'src/app/swagger';

@Directive({
	selector: '[orderStateColor]',
	standalone: true,
})
export class OrderStateColorDirective implements OnChanges {
	@Input({ required: true })
	orderStateColor: ORDERSTATE = ORDERSTATE.Canceled;

	protected _elementClass: string[] = [];

	@HostBinding('class')
	get elementClass(): string {
		return this._elementClass.join(' ');
	}
	set(val: string) {
		this._elementClass = val.split(' ');
	}

	constructor(private element: ElementRef) {
		this.statusClasses = {};
		this.statusClasses[ORDERSTATE.Overdue] = ['text-s-3-500', 'bg-s-3-500'];
		this.statusClasses[ORDERSTATE.Readyforinput] = [
			'text-s-2-500',
			'bg-s-2-500',
		];
		this.statusClasses[ORDERSTATE.Readyforpickup] = [
			'text-bl-1-500',
			'bg-bl-1-500',
		];
		this.statusClasses[ORDERSTATE.Pickedup] = [
			'text-s-1-500',
			'bg-s-1-500',
		];
		this.statusClasses[ORDERSTATE.Canceled] = [
			'text-gb-3-500',
			'bg-gb-3-500',
		];
		this.statusClasses[ORDERSTATE.Scheduled] = [
			'text-[#4146e5]',
			'bg-[#4146e5]',
		];
	}

	private statusClasses: { [status: number]: string };

	ngOnChanges(_) {
		this._elementClass = [
			'bg-opacity-10',
			...this.statusClasses[this.orderStateColor],
		];
	}
}
