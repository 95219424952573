import { OUTPUTSTATE } from './../../../../swagger/model/oUTPUTSTATE';
import { AsyncPipe, DatePipe, JsonPipe } from '@angular/common';
import {
	Component,
	effect,
	EventEmitter,
	input,
	Input,
	OnDestroy,
	output,
	Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { OrderStatusTranslateLabelPipe } from 'src/app/core/pipes/order-status-translate-label.pipe';
import {
	DemoDisplayDirective,
	NormalDisplayDirective,
} from 'src/app/core/services/state.service';
import { GetOrderDto, GetOrderResponse, ORDERSTATE } from 'src/app/swagger';
import { OrderOverviewService } from 'src/app/views/orders/order-overview/order-overview.service';
import { AppTooltipDirective } from '../../../directives/app-tooltip.directive';
import { OrderStateColorDirective } from '../../../directives/order-state-color.directive';
import { ConvertDateToLocalPipe } from '../../../pipes/convert-date-to-local.pipe';
import { DateRelativeDisplayPipe } from '../../../pipes/date-relative-display.pipe';
import { PrintService } from '../../../services/print.service';
import { UserService } from '../../../services/user.service';
import { IconsComponent } from '../../icons/icons.component';
import { ContactDetailsComponent } from '../contact-details/contact-details.component';
import { OrderActionComponent } from '../order-action/order-action.component';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import {
	OverviewDateLabelPipe,
	OverviewDateValuePipe,
} from './overview-date.pipe';
import { RowaActivityIndicatorModule } from '@rowa/ui';
import { DynamicLocaleService } from 'src/app/core/services/dynamic-locale.service';

@Component({
	selector: 'app-normal-order-overview',
	templateUrl: './normal-order-overview.component.html',
	styles: [],
	standalone: true,
	imports: [
		IconsComponent,
		OrderDetailsComponent,
		ContactDetailsComponent,
		AppTooltipDirective,
		OrderStateColorDirective,
		OrderActionComponent,
		DatePipe,
		TranslateModule,
		DateRelativeDisplayPipe,
		ConvertDateToLocalPipe,
		OrderStatusTranslateLabelPipe,
		OverviewDateLabelPipe,
		OverviewDateValuePipe,
		DemoDisplayDirective,
		NormalDisplayDirective,
		RowaActivityIndicatorModule,
		AsyncPipe,
	],
})
export class NormalOrderOverviewComponent implements OnDestroy {
	order = input<GetOrderDto>();
	isOrderDetails = input<boolean>(false);
	cancelOrder = output<any>();
	dispenseOrder = output<any>();
	printOrder = output<any>();

	locale: string;
	isSuperAdmin$: Observable<boolean>;
	ORDERSTATE = ORDERSTATE;
	OUTPUTSTATE = OUTPUTSTATE;

	subs: Subscription = new Subscription();

	constructor(
		private userService: UserService,
		private printService: PrintService,
		private overviewService: OrderOverviewService,
		private dynamicLocale: DynamicLocaleService,
	) {
		this.isSuperAdmin$ = this.userService.isSuperAdmin;
		this.locale = this.dynamicLocale.getCurRegionSetInStorage();
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	printPickup(order, event) {
		if (event != null) {
			event.stopPropagation();
		}

		this.printService.curPickupOrder.next(order);
	}

	openDialog(order, event) {
		if (event != null) {
			event.stopPropagation();
		}
		console.error(order, event, 'not implemented');
	}

	inputArticle(order: GetOrderResponse, event) {
		if (event != null) {
			event.stopPropagation();
		}

		this.overviewService.inputArticle(order);
	}
}
