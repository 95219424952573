import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ORDERSTATE } from 'src/app/swagger';
/*
 * Get the order status label translate key for an order status
 * Usage:
 *   value | orderStatusTranslateLabel | translate
 * Example:
 *   [label]="order.state | orderStatusTranslateLabel | translate"
 */
@Pipe({
	name: 'orderStatusTranslateLabel',
	standalone: true,
})
export class OrderStatusTranslateLabelPipe implements PipeTransform {
	constructor() {
		this.statusTranslateLabels = {};
		this.statusTranslateLabels[ORDERSTATE.Overdue] =
			'ORDERS.ORDEROVERVIEW.STATUS.OVERDUE';
		this.statusTranslateLabels[ORDERSTATE.Readyforinput] =
			'ORDERS.ORDEROVERVIEW.STATUS.READYFORINPUT';
		this.statusTranslateLabels[ORDERSTATE.Readyforpickup] =
			'ORDERS.ORDEROVERVIEW.STATUS.READYFORPICKUP';
		this.statusTranslateLabels[ORDERSTATE.Pickedup] =
			'ORDERS.ORDEROVERVIEW.STATUS.PICKEDUP';
		this.statusTranslateLabels[ORDERSTATE.Canceled] =
			'ORDERS.ORDEROVERVIEW.STATUS.CANCELED';
		this.statusTranslateLabels[ORDERSTATE.Scheduled] =
			'ORDERS.ORDEROVERVIEW.STATUS.SCHEDULED';
	}

	private statusTranslateLabels: { [status: number]: string };

	transform(value: ORDERSTATE): string {
		return this.statusTranslateLabels[value] || ORDERSTATE.Canceled;
	}
}
