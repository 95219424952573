<div class="grid grid-cols-9 gap-1 mb-2 text-xs text-g-3">
	<div class="col-span-2">
		{{ 'ORDERS.ORDEROVERVIEW.ORDER' | translate }}
	</div>
	<div class="col-span-2">
		{{ 'ORDERS.ORDEROVERVIEW.CONTACT' | translate }}
	</div>
	<div class="">
		{{ 'ORDERS.ORDEROVERVIEW.NEXT_UP' | translate }}
	</div>
	<div class="col-span-2">
		{{ 'ORDERS.ORDEROVERVIEW.STATUS.LABEL' | translate }}
	</div>
	<div class="col-span-2">
		{{ 'ORDERS.ORDEROVERVIEW.ACTION' | translate }}
		@if (!isOrderDetails) {
			<app-icons
				[iconName]="'faChevronRight'"
				class="float-right font-black cursor-pointer expand-pickup-item"
				name="ordereditOpener"
			/>
		}
	</div>
</div>

<div class="grid grid-cols-9 gap-1 text-[14px]">
	<div class="col-span-2">
		<app-order-details [order]="order" />
	</div>
	<div class="col-span-2">
		<app-contact-details [order]="order" />
	</div>
	<div class="">
		<span
			id="startDate"
			[appTooltip]="
				order.nextUpDate
					| convertDateToLocal
					| date: 'shortDate' : '' : locale
			"
		>
			{{
				order.nextUpDate
					| convertDateToLocal
					| dateRelativeDisplay: locale
			}}
		</span>
	</div>
	<div class="col-span-2">
		<span
			class="px-3 py-1 rounded-2xl"
			[orderStateColor]="order.isOverdue ? 'OVERDUE' : order.state"
			name="orderState"
			id="orderState"
		>
			{{
				(order.isOverdue ? 'OVERDUE' : order.state)
					| orderStatusTranslateLabel
					| translate
			}}
		</span>
	</div>
	<div class="col-span-2">
		<app-order-action
			*normalDisplay
			[text]="'ORDERS.ORDEROVERVIEW.MANAGE_ORDER.PICKUP_CODE.ACTION_TEXT'"
			(clickEvent)="printPickup(order, $event)"
		/>
		<app-order-action
			*demoDisplay="true"
			[text]="
				'ORDERS.ORDEROVERVIEW.MANAGE_ORDER.READY_FOR_INPUT.ACTION_TEXT'
			"
			(clickEvent)="readyforinput(order, $event)"
		/>
	</div>
</div>
