import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'convertDateToLocal',
	standalone: true,
})
export class ConvertDateToLocalPipe implements PipeTransform {
	transform(value: string | Date): string | Date {
		if (value == null) return undefined;
		if (typeof value === 'object') return value;
		return value?.endsWith('Z') ? value : value + 'Z';
	}
}
