import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { localesPool } from 'src/assets/localesPool';
import { LanguageService } from '../services/language.service';

@Pipe({
	name: 'dateRelativeDisplay',
	standalone: true,
})
export class DateRelativeDisplayPipe implements PipeTransform {
	private localesPool = [...localesPool];

	constructor(private languageService: LanguageService) {}
	transform(val: Date | string, locale: string): string {
		if (val == null) return '-';
		const curdateFNSLocaleAsString = this.localesPool.find(
			(x) => x.value === locale,
		).dateFNSLocale;
		return formatDistanceToNow(new Date(val), {
			addSuffix: true,
			locale: this.languageService.getDateFNSLocale(
				curdateFNSLocaleAsString,
			),
		});
	}
}
