import { Pipe, PipeTransform } from '@angular/core';
import {
	CANCELLATION_REASON,
	CANCELLATION_REASONS,
} from '../model/cancellation-reason.model';
/*
 * Get the order status label translate key for an order status
 * Usage:
 *   value | orderStatusTranslateLabel | translate
 * Example:
 *   [label]="order.state | orderStatusTranslateLabel | translate"
 */
@Pipe({
	name: 'cancellationReasonTranslateLabel',
	standalone: true,
})
export class CancellationReasonTranslateLabelPipe implements PipeTransform {
	constructor() {
		this.cReasonTranslateLabels = {};
		this.cReasonTranslateLabels[
			CANCELLATION_REASONS.CUSTOMER_NOT_RESPONDING
		] = 'ORDERS.ORDEROVERVIEW.CANCEL.REASON.CUSTOMER_NOT_RESPONDING';
		this.cReasonTranslateLabels[CANCELLATION_REASONS.DELIVERY_ISSUE] =
			'ORDERS.ORDEROVERVIEW.CANCEL.REASON.DELIVERY_ISSUE';
		this.cReasonTranslateLabels[CANCELLATION_REASONS.CUSTOMER_WISH] =
			'ORDERS.ORDEROVERVIEW.CANCEL.REASON.CUSTOMER_WISH';
		this.cReasonTranslateLabels[CANCELLATION_REASONS.STORAGE_ISSUE] =
			'ORDERS.ORDEROVERVIEW.CANCEL.REASON.STORAGE_ISSUE';
		this.cReasonTranslateLabels[CANCELLATION_REASONS.OTHER] =
			'ORDERS.ORDEROVERVIEW.CANCEL.REASON.OTHER';
	}

	private cReasonTranslateLabels: { [status: number]: string };

	transform(value: CANCELLATION_REASON): string {
		return this.cReasonTranslateLabels[value] || CANCELLATION_REASONS.OTHER;
	}
}
